
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PopupComponent extends Vue {
  @Prop({ type: String, required: true }) message?: any;
  @Prop({ type: String, required: true }) title?: any;
  @Prop({ type: Number, required: false, default: 70000 }) duration?: any;

  showPopup = true;
  progressBarWidth = 100;
  intervalId?: number;

  created() {
    const totalTime = this.duration; // Total time the popup shows for
    this.intervalId = window.setInterval(() => {
      this.progressBarWidth -= (100 / totalTime) * 30;
      if (this.progressBarWidth <= 0) {
        this.clearInterval();
      }
    }, 30);
  }

  beforeDestroy() {
    this.clearInterval();
  }

  private clearInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.showPopup = false;
      this.$emit('close');
    }
  }
}
